import React, { useState, useEffect } from 'react'
import 'twin.macro'
import './Logo.css'

const LogoSvg = ({ active }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="200 200 600 600"
    tw="w-full h-full"
    className={active ? 'active' : ''}
  >
    <g id="レイヤー_4">
      <path
        className="st0 svg-elem-1"
        d="M375.62,437.95c20.96-20.72,55.4-26.26,81.8-13.14s42.79,43.91,38.94,73.12
  c-3.85,29.22-27.67,54.71-56.56,60.53c-28.89,5.82-60.72-8.46-75.59-33.9C349.33,499.12,350.67,462.63,375.62,437.95z"
      ></path>
      <path
        className="st0 svg-elem-2"
        d="M473.9,368.52c-8.08,32.91-26.39,121-30.3,177.16c-0.68,9.79,5.93,23.88,18.3,25.06
  c13.04,1.25,21.1-8.46,24.18-18.67c6.45-21.41,21.47-107.03,33.56-171.25c6.31-33.49,3.19-44.98-13.73-48.04
  C489.57,329.84,480.55,341.43,473.9,368.52z"
      ></path>
      <path
        className="st0 svg-elem-3"
        d="M516.59,420.99c-15.91-2.28-22.56,9.61-25.57,26.98c-5.76,33.21-10.95,59.46-12.05,80.52
  c-0.54,10.23-2.35,25.01,18.25,27.87c20.11,2.8,26.07-8.88,27.53-19.03c4.27-29.78,6.82-50.57,11.09-80.35
  C537.84,442.94,537.09,424.56,516.59,420.99z"
      ></path>
      <path
        className="st1 svg-elem-4"
        d="M514.76,378.94c10.36-2.75,22.29,3.92,25.37,14.18c3.09,10.26-3.18,22.4-13.34,25.83s-22.5-2.45-26.25-12.48
  S501.76,382.39,514.76,378.94z"
      ></path>
      <path
        className="st0 svg-elem-5"
        d="M548.65,372.84c-8.08,32.91-26.39,121-30.3,177.16c-0.68,9.79,5.93,23.88,18.3,25.06
  c13.04,1.25,21.1-8.46,24.18-18.67c6.45-21.41,21.47-107.03,33.56-171.25c6.31-33.49,3.19-44.98-13.73-48.04
  C564.32,334.15,555.3,345.75,548.65,372.84z"
      ></path>
      <path
        className="st0 svg-elem-6"
        d="M666.83,455.98c-14.02-21.35-39.88-34.34-65.37-32.83c-25.49,1.51-49.65,17.45-61.05,40.3
  c-11.41,22.85-9.63,51.73,4.49,73.01c12.41,18.7,36.68,25.61,59.03,23.63c17.42-1.54,40.46-14.21,51.85-39.33
  C665.04,500.32,678.78,474.18,666.83,455.98z"
      ></path>
      <path
        className="st1 svg-elem-7"
        d="M585.89,477.89c8.69,7.66,11.46,13.98,9.35,21.71c-0.3-8.73,5.77-17.3,14.11-19.9"
      ></path>
      <path
        className="st1 svg-elem-8"
        d="M414.03,477.89c8.69,7.66,11.46,13.98,9.35,21.71c-0.3-8.73,5.77-17.3,14.11-19.9"
      ></path>
      <path
        className="st0 svg-elem-9"
        d="M742.61,434.93c-27.06-16.08-62.68-18.74-90.06-3.21c-27.38,15.53-43.61,50.24-34.37,80.33
  c9.24,30.09,38.31,48.9,74.82,38.21c-11.61,10.8-20.94,24.84-22.91,40.57c-1.97,15.73,0.37,32.99,18.54,40.79
  c19.55,8.4,39.99-4.54,45.97-15.88c19.08-36.14,24-118.51,8.27-178.7C742.82,436.58,742.78,436.11,742.61,434.93z"
      ></path>
      <path
        className="st1 svg-elem-10"
        d="M670.7,474.82c8.43,2.33,14.18,11.81,12.35,20.36c-2.64-8.71,4.28-18.96,13.28-20.44
  c-0.08-0.08-0.15-0.15-0.23-0.23"
      ></path>
      <path
        className="st0 svg-elem-11"
        d="M771.97,474.52c6.33-7.19,4.26-39.47-12.32-48.31c-16.58-8.83-40.08,1.83-48.06,18.83
  c-7.49,15.97-10.26,29.97-12.46,43.48c-4.9,30.08,12.74,62.47,42.11,70.62c29.37,8.15,62.48-6.88,79.01-32.49
  c16.53-25.61,17.43-59.51,6.02-87.77c-4.49-11.11-17.1-18.32-28.95-16.54c-11.85,1.78-21.79,12.37-22.82,24.31"
      ></path>
      <path
        className="st0 svg-elem-12"
        d="M760.87,476.37c5.4-0.31,10.79-0.62,16.19-0.93"
      ></path>
      <path
        className="st0 svg-elem-13"
        d="M852.68,473.84c10.21-15.02,6.1-40.73-10.81-47.34c-14.6-5.7-37.38,0.22-46.43,14.19
  c-9.95,15.36-16.1,33.18-19.26,51.21c-3.15,18.03-0.75,37.85,10.56,52.23c11.31,14.39,32.8,21.34,48.91,12.65
  c-24.99,7.44-37.47,41.47-23.21,63.3c14.26,21.83,50.44,24.08,67.29,4.18c40.59-47.91,57.87-114.7,45.62-176.29
  c-3.13-15.75-18.34-28.21-34.4-28.18s-30.22,10.16-33.3,25.92"
      ></path>
      <path
        className="st0 svg-elem-14"
        d="M846.45,476.1c5.55-0.15,11.11-0.3,16.66-0.45"
      ></path>
    </g>
    <g id="レイヤー_3">
      <path
        className="st2 svg-elem-15"
        d="M317.08,427.36c-24.95-21.85-33.77-60.22-20.87-90.78c12.9-30.56,46.55-50.99,79.62-48.35
  c33.06,2.64,63.04,28.17,70.92,60.39c7.88,32.22-6.6,70.24-34.71,87.85C383.93,454.08,343.7,450.67,317.08,427.36z"
      ></path>
      <path
        className="st0 svg-elem-16"
        d="M145.95,489.93c51.69-111.17,171.18-155.11,199.31-26.84c2.32,13.65,1.1,24.76,29.27,34.5
  c56.96,19.69,56.45,80.65,2.52,139.83c-13.04,14.32-31.57,30.68-52.19,40.96c-14.88,7.42-33.51,13.29-52.98,14.44
  c-47.03,2.78-45.3-41.23-8.58-72.07c25.61-22.03,28.62-42.02-9.84-30.9C166.72,614.95,120.31,557.31,145.95,489.93z"
      ></path>
      <path
        className="st3 svg-elem-17"
        d="M444.65,392.4c14.49-34.96,8.39-79.87-16.64-108.25c-25.03-28.39-69.44-42.67-105.95-32.66
  c-37.55,10.29-68.69,43.47-74.55,82.04c-1.64,10.77-7.14,20.63-15.42,27.71c-9.13,7.79-19.32,14.55-26.77,23.96
  c-7.4,9.34-11.7,22.34-7.05,33.31c4.65,10.97,20.7,16.59,29.22,8.25c-1.34,7.36,3.95,15.3,11.26,16.89s15.42-3.43,17.26-10.68
  c-0.88,5.99,3.11,12.33,8.89,14.13c5.78,1.8,12.66-1.16,15.34-6.59c6.78,11.7,28.49,7.43,30.37-5.96
  c5.72-40.71,37.4-84.41,64.6-100.19c-1.44,17.99,7.35,33.58,20.83,45.58C409.51,391.92,429.88,395.41,444.65,392.4z"
      ></path>
      <path
        className="st4 svg-elem-18"
        d="M337.69,473.92c-13.37,11.43-16.64,24.75-7.34,33.35c11.35,10.09,32.33-1.79,38.32-13.12"
      ></path>
      <path
        className="st5 svg-elem-19"
        d="M397.02,442.53c1.61,0.19,2.98-1.37,2.61-3.01c-2.61-11.4-10.93-21.09-21.58-24.81
  c-3.82-1.33-8.56,0.41-10.74,4.07c-2.18,3.66-1.46,8.88,1.7,11.45C377.11,436.84,386.8,441.34,397.02,442.53z"
      ></path>
      <path
        className="st4 svg-elem-20"
        d="M379.82,498.56c-4.76,6.13-11.93,19.9,0.35,24.24c9.64,3.41,19.3-10.28,23.1-18.33"
      ></path>
    </g>
    <g id="レイヤー_5">
      <path className="st0 svg-elem-21" d="M-222.76,1150.82"></path>
      <path
        className="st6 svg-elem-22"
        d="M630.94,367.52c2.63-2.28,10.05-9.74,13.48-10.35c4.63-0.83,7.01,4.35,3.34,8.03
  c-4.53,4.53-10.67,10.2-12.23,19.32c-2.25,13.09,4.84,18.07,16.7,17.68c14.32-0.47,18.82-13.64,17.7-23.08
  c-0.41-3.44,0.35-5,2.42-6.28c3.2-1.98,8.5,1.25,8.85,7.27"
      ></path>
      <path
        className="st6 svg-elem-23"
        d="M688.37,357.08c4,1.89,6.6,5.53,8.73,9.14c2.13,3.61,4.04,7.45,7.3,10.28c3.26,2.83,8.34,4.43,12.36,2.57
  c4.02-1.85,5.18-6.84,1.21-9.99c-7.84-6.21-22.03-1.13-26.66,7.33c-4.62,8.46-5.77,20.68,9.69,23.72
  c16.33,3.21,23.57-4.14,27.02-13.05"
      ></path>
      <path
        className="st6 svg-elem-24"
        d="M740.69,357.31c-4.96,3.99-4.53,13.7,0.76,17.14c5.29,3.44,11.97-1.17,13.96-7.58
  c2.54-8.16,10.81-9.42,14.9-0.18c4.28,9.67,1.25,25.41-7.29,30.6c-7.65,4.65-20.41,3.55-27.28-8.5"
      ></path>
      <path
        className="st6 svg-elem-25"
        d="M783.19,372.54c0.93-6.85,7.96-14.1,17.51-14.44c10.6-0.38,11.53,6.03,10.33,13.44"
      ></path>
      <path
        className="st6 svg-elem-26"
        d="M781.27,393.79c8.24,11.88,39.19,1.51,39.79-7.69"
      ></path>
      <path
        className="st0 svg-elem-27"
        d="M820.35,366.73c0.45,2.36,0.96,5.72,1.42,8.08"
      ></path>
      <path
        className="st6 svg-elem-28"
        d="M827.02,365.86c0.64,2.72,1.27,5.45,1.91,8.17"
      ></path>
      <path
        className="st6 svg-elem-29"
        d="M834.85,355.53c6.6,5.12,6.18,13.95,10.59,18.79c4.41,4.84,16.97,5.7,22.42,2.06s3.07-12.99-3.39-14.15
  c-9.72-1.75-23.19,10.54-25.13,16.34c-2.35,7.04-1.81,15.61,7.15,18.87c14.21,5.18,28.55-2.39,31.85-11.66"
      ></path>
    </g>
  </svg>
)

const Logo = () => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    const timerId = setTimeout(() => {
      setActive(true)
    }, 600)

    return () => {
      clearTimeout(timerId)
    }
  })

  return <LogoSvg active={active} />
}

export default Logo
